import React from 'react';

function HomePage() {
  return (
    <>
      <h1>
        Merry Christmas, Amy!
      </h1>
    </>
  );
}

export default HomePage;